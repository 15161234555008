import React from 'react';

import SEO from '../components/seo';
import Layout from '../components/layout';
import SplitView from '../components/splitview';
import CtaButton from '../components/cta-button';

const About = ({ location }) => {
  return (
    <Layout location={location}>
      <SEO title="Projects" />
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <SplitView>
          <div className="about">
            <h1>About Renno Specialists</h1>
            <h3>Integrity</h3>
            <p>At Renno Specialists, we live the philosophy of “Constructing with 
              Integrity.” This commitment to excellence symbolizes our philosophy 
              of doing business. It articulates our fundamental values of hard work, 
              thrift, honesty and fairness. 
              It reminds us to extend respect to all people and honor their individuality.
              It means listening to understand—then fulfilling our commitments made.
            </p>
            <br></br>
            <h3>Quality</h3>
            <p>
              Quality defines the standards of excellence we build into every structure 
              and relationship. Whether it’s found in our precision scheduling, detailed 
              estimating, open-book project accounting or forthright communication, 
              our management style stands as a symbol of quality and first-class work.
            </p>
            <br></br>
            <h3>Commitment</h3>
            <p>
              This defines our commitment to our associates—the people with whom we partner
               on behalf of the client—including owners, vendors and the community. 
              We are dedicated to working together, respecting everyone’s interests and 
              maintaining a common agenda for contract fulfillment. 
              We believe that increased effort on behalf of our clients—as well as closer 
              alliances with our colleagues—will result in fair business practices, open 
              communication and high levels of trust within our relationships.
            </p>
            <CtaButton biggerTopMargin />
          </div>
        </SplitView>
      </div>
    </Layout>
  )
}

export default About